<template>
	<div>
		<div class="flex wrap">
			<div v-for="(item,index) in pics" :key="index" class="show-upload-list-item">
				<img :src="item" />
				<div class="show-action">
					<div class="flex space alcenter">
						<a-icon @click="showDiv(index)" type="eye" />
						<a-icon @click="delPic(index)" style="margin-left: 15px;" type="delete" />
					</div>
				</div>
			</div>
			<!--原有的不太好用-->
			<div v-if="value.length<num" @click="showAlbum =true" class="show-upload-list-item">
				<div style="width: 100%;height: 100%;" class="flex center alcenter">
					<a-icon type="plus"  />
				</div>
			</div>
		</div>
		<wyu-album v-if="showAlbum" type="multi" @closed="closed" @change="changePhoto"></wyu-album>
		<a-modal :title="null" v-model="showDivAlert" :footer="null">
			<img style="width: 100%;" :src="pics[selectIndex]" />
		</a-modal>
	</div>
</template>

<script>
	import wyuAlbum from './album.vue';
	export default {
		components:{
			wyuAlbum
		},
		props: {

			value: {
				type: Array,
				default: function() {
					return new Array;
				}
			},
			num:{
				type:Number,
				default:10,
			}
		},
		computed: {
			pics() {
				return this.value;
			}
		},
		created() {
			console.log(this.value);
		},
		data() {
			return {
				showDivAlert: false,
				selectIndex: 0,
				showAlbum:false
			}
		},
		methods: {
			changePhoto(data){
				let pics = JSON.parse(JSON.stringify(this.value));
				for(var a in data){
					pics.push(data[a]);
				}
				this.$emit('input', pics);
			},
			closed(){
				this.showAlbum = false;
			},
			showDiv(index) {
				this.selectIndex = index;
				this.showDivAlert = true;
			},
			delPic(index) {

				this.$confirm({
					title: '确定要删除吗？',
					content: '删除该图片',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let pics = JSON.parse(JSON.stringify(this.value));
						pics.splice(index, 1);
						this.$emit('input', pics);
					},
					onCancel: () => {
						console.log('Cancel');
					},
				});
			},
			
		},
	}
</script>

<style>
	.show-upload-list-item {
		padding: 8px;
		margin-right: 8px;
		margin-bottom: 8px;
		text-align: center;
		vertical-align: top;
		background-color: #fafafa;
		border: 1px dashed #d9d9d9;
		border-radius: 4px;
		cursor: pointer;
		-webkit-transition: border-color 0.3s ease;
		transition: border-color 0.3s ease;
		overflow: hidden;
		width: 128px;
		height: 128px;
		position: relative;
	}

	.show-upload-list-item .show-action {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.3);
		opacity: 0;
		transition: opacity 0.3s;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #FFFFFF;
		font-size: 24px;
	}

	.show-upload-list-item:hover .show-action {
		opacity: 1;
	}

	.show-upload-list-item:hover {
		border-color: #4772FF;
	}

	.show-upload-list-item img {
		object-fit: cover;
		width: 110px;
		height: 110px;
	}


</style>
